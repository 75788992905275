import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby'
import Layoutas from '../../components/Layout';
import RefDataDate from '../../components/RefDataDate';
import Mapbox from '../../mapbox'
import LegendCard from '../../mapbox/LegendCard'
import Chart from '../../components/Chart';
import Calculate from '../../util/calculate';

import { Select, Table } from 'antd';
import '../index.css';

import TumbonAddr from '../../util/TumbonAddr'

import newnew from '/images/wepik-photo-mode-2022619-143425.webp';

import api from '../../util/api';

const { Option } = Select;

const PagePMS = (props) => {
  const [initData, setInitData] = useState(null)
  const [filter, setFilter] = useState({
    view: 'country',
    year: "2564",
  });
  const [data, setData] = useState({
    group_institute: [],
    group_province: [],
    group_target: []
  })
  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#a5d6a7',])
  const chart_color = ['#ca498c', '#a2539b', '#b977ac', '#cf9bbd', '#e6bfce', '#fde3df']
  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  useEffect(async () => {
    let dataSummaryAllyear = await api.getInitData("pms-overview");
    setInitData(dataSummaryAllyear);
  }, [])

  useEffect(() => {
    if(initData){
    data.group_institute = initData.group_institute.filter(item => item.year === filter.year)
    data.group_province = initData.group_province.filter(item => item.year === filter.year && item.province_name)
    data.group_target = initData.group_target.filter(item => item.year === filter.year && item.name)
    setData({ ...data })

    let response = onProcessData({
      province: filter['province_name'],
      layer: filter.view
    }, convertMapContent(filter.view, data.group_province))
    setDataMap([
      { id: 'border', layer: filter.view, data: response.data }
    ])
    let data_legend = [
      { name: 'จำนวนโครงการทั้งหมด', value: response.data.reduce((acc, cur) => acc + cur.pmstargetGroup, 0), unit: 'โครงการ' },
      // { name: 'นักเรียน', value: response.data.reduce((acc, cur) => acc + cur.studentOnlyGroup, 0), unit: 'โครงการ' },
      { name: 'บุคลากรทางการศึกษาอื่นๆ', value: response.data.reduce((acc, cur) => acc + cur.otherPeopleGroup, 0), unit: 'โครงการ' },
      { name: 'นักเรียน/นักศึกษา', value: response.data.reduce((acc, cur) => acc + cur.studentAllGroup, 0), unit: 'โครงการ' },
      { name: 'เด็กและเยาวชนด้อยโอกาส', value: response.data.reduce((acc, cur) => acc + cur.childrenGroup, 0), unit: 'โครงการ' },
      { name: 'ครู', value: response.data.reduce((acc, cur) => acc + cur.teacherGroup, 0), unit: 'โครงการ' },
      { name: 'สถานศึกษา', value: response.data.reduce((acc, cur) => acc + cur.schoolGroup, 0), unit: 'โครงการ' },
      { name: 'องค์กร', value: response.data.reduce((acc, cur) => acc + cur.organizationGroup, 0), unit: 'โครงการ' },
      // { name: 'อื่นๆ', value: response.data.reduce((acc, cur) => acc + cur.otherGroup, 0), unit: 'โครงการ' },
    ]

    setLegendData({
      ...legend_data,
      title: filter.province_name,
      subtitle: filter.city_name,
      data: data_legend,
    })
  }
  }, [filter,initData])

  const convertMapContent = (layer, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => {
      return {
        province: item,
        pmstargetGroup: 0,
        studentOnlyGroup: 0,
        otherPeopleGroup: 0,
        studentAllGroup: 0,
        otherGroup: 0,
        childrenGroup: 0,
        teacherGroup: 0,
        schoolGroup: 0,
        organizationGroup: 0
      }
    });

    data.forEach((item) => {
      let pmstargetGroup = (
        item.studentOnlyGroup +
        item.otherPeopleGroup +
        item.studentAllGroup +
        item.otherGroup +
        item.childrenGroup +
        item.teacherGroup +
        item.schoolGroup +
        item.organizationGroup
      )
      let studentOnlyGroup = (item.studentOnlyGroup)
      let otherPeopleGroup = (item.otherPeopleGroup)
      let studentAllGroup = (item.studentAllGroup)
      let otherGroup = (item.otherGroup)
      let childrenGroup = (item.childrenGroup)
      let teacherGroup = (item.teacherGroup)
      let schoolGroup = (item.schoolGroup)
      let organizationGroup = (item.organizationGroup)
      let index = province.findIndex(ele => ele.province === item.province_name)
      !province[index]['pmstargetGroup'] ? province[index]['pmstargetGroup'] = pmstargetGroup : province[index]['pmstargetGroup'] += pmstargetGroup
      !province[index]['studentOnlyGroup'] ? province[index]['studentOnlyGroup'] = studentOnlyGroup : province[index]['studentOnlyGroup'] += studentOnlyGroup
      !province[index]['otherPeopleGroup'] ? province[index]['otherPeopleGroup'] = otherPeopleGroup : province[index]['otherPeopleGroup'] += otherPeopleGroup
      !province[index]['studentAllGroup'] ? province[index]['studentAllGroup'] = studentAllGroup : province[index]['studentAllGroup'] += studentAllGroup
      !province[index]['otherGroup'] ? province[index]['otherGroup'] = otherGroup : province[index]['otherGroup'] += otherGroup
      !province[index]['childrenGroup'] ? province[index]['childrenGroup'] = childrenGroup : province[index]['childrenGroup'] += childrenGroup
      !province[index]['teacherGroup'] ? province[index]['teacherGroup'] = teacherGroup : province[index]['teacherGroup'] += teacherGroup
      !province[index]['schoolGroup'] ? province[index]['schoolGroup'] = schoolGroup : province[index]['schoolGroup'] += schoolGroup
      !province[index]['organizationGroup'] ? province[index]['organizationGroup'] = organizationGroup : province[index]['organizationGroup'] += organizationGroup
      res = province
    })
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['pmstargetGroup'] / element['pmstargetGroup'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['pmstargetGroup'] / result.map(item => item.pmstargetGroup).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })
    let data_color = []
    if (filter.region !== 'ทั้งหมด') {
      data_color = [
        { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.pmstargetGroup)), count: 0, text: `มาก` },
        { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.pmstargetGroup)), count: 0, text: `ปานกลาง` },
        { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.pmstargetGroup)), count: 0, text: `ปานกลาง` },
        { color: border_color[3], value: 0, count: 0, text: `น้อย` },
        { color: border_color[4], value: -100, count: 0, text: `น้อย` },
      ]
    } else {
      data_color = [
        { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.pmstargetGroup)), count: 0, text: `มาก` },
        { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.pmstargetGroup)), count: 0, text: `ปานกลาง` },
        { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.pmstargetGroup)), count: 0, text: `ปานกลาง` },
        { color: border_color[3], value: 0, count: 0, text: `น้อย` },
        { color: border_color[4], value: -100, count: 0, text: `น้อย` },
      ]
    }
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.pmstargetGroup)
      // element['opacity'] = 0.5
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  return (
    <Layoutas Role={['eef']}>
      < div className='bg-gray-200'>
        <div style={{
          width: '100%',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          backgroundImage: `url(${newnew})`,
          display: 'flex'
        }} >
          <h1 className="font-bold text-2xl md:text-3xl xl:text-5xl text-center text-white mt-[120px] w-full ">ระบบบริหารจัดการโครงการ (PMS)</h1>
        </div>
        <div className="max-w-[1300px] rounded-3xl m-auto -mt-[70vh] p-2 md:p-4 lg:8 bg-white bordered">
          <div className="font-bold text-xl lg:text-3xl mb-2 text-[#0E3165] text-center">จำนวนโครงการจำแนกตามกลุ่มเป้าหมาย</div>
          <div className="flex flex-row mb-2">
            <div>
              <span className="text-sm m-0 ml-[9px]" >ปี: </span>
              <Select
                showSearch
                placeholder="ปีการศึกษา"
                value={filter.year}
                style={{ width: 180, margin: 4 }}
                onChange={(val) => {
                  setFilter({ ...filter, year: val })
                }}
              >
                {
                  [...new Set(['2565', '2564', '2563', '2562', '2561'])]
                    .filter(x => x !== 'ไม่ระบุ')
                    .sort()
                    .map(x => <Option value={x}>{x}</Option>)
                }
              </Select>
            </div>
          </div>

          <div className="col-span-11">
            <div className="relative w-full h-full rounded overflow-hidden shadow-card bg-slate-500">
              <Mapbox
                id={'map-pms'}
                data={data_map}
                zoom={5.2}
                className="map-pms-container"
                style={"border_basic"}
                filter={filter}
                notic_title={'จำนวนพื้นที่ดำเนินงานทั้งหมดของ กสศ.'}
              />
              <div className='absolute top-[10px] left-[10px] z-1'>
                <LegendCard {...legend_data} />
              </div>
              <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
                <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                  <span className='font-bold text-sm md:text-base center mx-1 mb-2'>สัดส่วนกลุ่มเป้าหมายที่ได้รับความช่วยเหลือ</span>
                  <div className='flex'>
                    <div className='flex flex-col'>
                      <div class={`h-3 w-10 md:w-16 bg-[#1b5e20]`}>
                      </div>
                      <div class="font-bold text-left">
                        มาก
                      </div>
                    </div>
                    <div className='flex flex-col'>
                      <div class={`h-3 w-10 md:w-16 bg-[#388e3c]`}>
                      </div>
                    </div>
                    <div className='flex flex-col'>
                      <div class={`h-3 w-10 md:w-16 bg-[#66bb6a]`}>
                      </div>
                    </div>
                    <div className='flex flex-col'>
                      <div class={`h-3 w-10 md:w-16 bg-[#a5d6a7]`}>
                      </div>
                      <div class="font-bold text-right">
                        น้อย
                      </div>
                    </div>
                    <div className='ml-4 flex flex-col'>
                      <div class={`h-3 w-10 md:w-16 bg-[#94a3b8]`}>
                      </div>
                      <div class="font-bold text-center">
                        ไม่มีข้อมูล
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className='grid grid-cols-1 xl:grid-cols-2 gap-4'>
            <div className='col-span-1'>
              <Chart
                containerProps={{ style: { height: "500px" } }}
                className='p-4'
                options={{
                  type: 'pieNotEdges',
                  title: 'จำนวนโครงการแยกตามกลุ่มเป้าหมาย',
                  plotOptions: {
                    pie: {
                      allowPointSelect: true,
                      cursor: 'pointer',
                      showInLegend: true,
                      colors: chart_color,
                      dataLabels: {
                        distance: -30,
                        format: '{point.percentage:.1f} %'
                      }
                    },
                  },
                }}
                data={
                  [{
                    name: 'จำนวนโครงการ',
                    colorByPoint: true,
                    data: data.group_target.map(item => { return { name: item.name, y: item.countProject } }),
                  }]
                } />
            </div>
            <div className='col-span-1'>
              <Chart
                containerProps={{ style: { height: "500px" } }}
                className='p-4'
                options={{
                  type: 'spiderWeb',
                  title: '',
                  categories: data.group_target.map(item => item.name)
                }}
                data={
                  [{
                    name: 'จำนวนโครงการ',
                    data: data.group_target.map(item => item.countProject),
                    pointPlacement: 'on'
                  }]
                } />
            </div>
          </div>
          <RefDataDate
            title=""
            subjectTitle="*ประมวลผล ณ เดือนเมษายน 2565"
          />
        </div >
      </div >
    </Layoutas >
  )

}

export default PagePMS
